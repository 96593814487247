<template>
  <div class="cancel-order bsbb">
    <van-icon name="arrow-left" @click="$router.go(-1)" class="font-size-30" />
    <p class="mb20">订单已取消</p>
    <div class="content p20 bsbb">
      <div class="title">
        <p class="font-size-30 fw7">
          菜大全鲜果蔬菜成都店<van-icon name="arrow" />
        </p>
      </div>
      <div class="df aic jcsb msg mt20">
        <div class="df aic font-size-24 fw7">
          <img src="../../assets/images/xg/list5.png" class="mr20" />
          <p>[新鲜到货] 智利进进口4J车厘子100h</p>
        </div>
        <div class="df fdc re">
          <p class="font-size-24">实付49.3</p>
          <div class="font-size-16">69.3</div>
        </div>
      </div>
      <div class="mt20 mb20 font-size-22 tip">
        <div class="df aic jcsb mt20">
          <span>包装费</span>
          <span>¥2</span>
        </div>
        <div class="df aic jcsb mt20">
          <div class="df fdc aic">
            <span>配送费</span>
            <span class="red">免费配送</span>
          </div>
          <span>¥0</span>
        </div>
        <div class="re font-size-24 mt20">
          <p>
            <span class="mr20">总优惠 <i style="color: red">¥24.5</i></span>
            <span class="fw7 font-size-26"
              >实付款51.3<van-icon name="arrow-up"
            /></span>
          </p>
        </div>
      </div>
      <div class="address font-size-24">
        <div class="df aic jcsb">
          <span>收货地址</span>
          <span>华阳镇街道天目中心2号楼一楼大厅右侧</span>
        </div>
        <div class="df aic jcsb">
          <span>收货人</span>
          <span>南柱赫17608139720</span>
        </div>
        <div class="df aic jcsb">
          <span>如遇缺货</span>
          <span>缺货时电话与我联系</span>
        </div>
        <div class="df aic jcsb">
          <span>订单号</span>
          <div class="df aic">
            <span class="mr20">4050 3500 9693 3905 055 </span>
            <img src="../../assets/images/xg/copyicon.png" class="copy" />
          </div>
        </div>
        <div class="df aic jcsb">
          <span>送达时间</span>
          <span>尽快送达[19:55]</span>
        </div>
        <div class="df aic jcsb">
          <span>配送方式</span>
          <span>骑士专送</span>
        </div>
        <div class="df aic jcsb">
          <span>支付方式</span>
          <span>在线支付</span>
        </div>
        <div class="df aic jcsb">
          <span>下单时间</span>
          <span>2022 12 23 00:00</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.cancel-order {
  width: 100%;
  height: 100%;
  padding: 20px 30px;
  .content {
    width: 684px;
    height: 100%;
    border-radius: 20px;
    background: rgba(255, 255, 255, 1);
    .msg {
      img {
        width: 84px;
        height: 84px;
      }
      .re {
        text-align: right;
        > div {
          text-decoration: line-through;
          color: #ddd;
        }
      }
    }
    .tip {
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 20px;
      .red {
        color: red;
        font-size: 14px !important;
      }
      .re {
        text-align: right;
      }
    }
    .address {
      > div {
        margin-top: 20px;
      }
      .copy {
        width: 18.68px;
        height: 18.68px;
        opacity: 0.75;
      }
    }
  }
}
</style>